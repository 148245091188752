<template>
	<div v-loading="fullscreenLoading"  style="    box-shadow: rgb(0 0 0 / 3%) 0px 4px 12px;
    border-radius: 0.7vw;
    margin-top: 33px;
    padding: 11px;
    background: #fff;">
		<!--ที่อยู่จัดส่ง-->
		<div class="item-block" v-if="orderPaymentData.is_virtual == 0">
			<div class="block-text" style="    font-weight: 600;"  >ที่อยู่จัดส่ง</div>

			<div clsas="address-list">
				<div class="address-item" @click="addAddressShow">
					<div class="add-address">
						<i class="el-icon-circle-plus-outline"></i>
						เพิ่มที่อยู่สําหรับจัดส่ง
					</div>
				</div>

				<div class="address-item" v-for="(item, key) in memberAddress" :key="item.id" :class="addressId == item.id ? 'active' : ''"
				 v-if="key < 3 || (addressShow && key >= 3)">
					<div class="address-info">
						<div class="options">
							<div @click="editAddress(item.id)">แก้ไข</div>
							<template v-if="item.is_default == 0">
								<el-popconfirm title="คุณแน่ใจหรือไม่ว่าคุณต้องการลบที่อยู่？" @onConfirm="deleteMemberAddress(item.id)">
									<div slot="reference">ลบ</div>
								</el-popconfirm>
							</template>
						</div>
						<div class="address-name">{{ item.name }}</div>
						<div class="address-mobile" @click="setMemberAddress(item.id)">{{ item.mobile }}</div>
						<div class="address-desc" @click="setMemberAddress(item.id)">{{ item.full_address }} {{ item.address }}</div>
					</div>
				</div>

				<div v-if="memberAddress.length > 3 && !addressShow" @click="addressShow = true" class="el-button--text address-open">
					<i class="el-icon-arrow-down"></i>
					ที่อยู่จัดส่งเพิ่มเติม
				</div>

				<div class="clear"></div>
			</div>
		</div>

		<!--ในการซื้อผลิตภัณฑ์เสมือนจริงคุณต้องกรอกหมายเลขโทรศัพท์มือถือของคุณ-->
		<div class="item-block" v-if="orderPaymentData.is_virtual == 1">
			<div class="block-text">ในการซื้อผลิตภัณฑ์เสมือนจริงคุณต้องกรอกหมายเลขโทรศัพท์มือถือของคุณ，เพื่อให้ผู้ขายติดต่อคุณได้ง่ายขึ้น</div>

			<el-form ref="form" size="mini" class="mobile-wrap" label-width="80px">
				<el-form-item label="หมายเลขโทรศัพท์มือถือ">
					<el-input placeholder="กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ" maxlength="11" v-model="orderCreateData.member_address.mobile"></el-input>
				</el-form-item>
			</el-form>
		</div>

		<!--เพิ่มที่อยู่จัดส่งแล้ว-->
		<el-dialog :title="addressForm.id == 0 ? 'เพิ่มที่อยู่สําหรับจัดส่ง' : 'แก้ไขที่อยู่สําหรับจัดส่ง'" :visible.sync="dialogVisible" width="32%">
			<el-form ref="form" :rules="addressRules" :model="addressForm" label-width="80px">
				<el-form-item label="ชื่อ" prop="name">
					<el-input v-model="addressForm.name" placeholder="ชื่อของผู้รับสินค้า"></el-input>
				</el-form-item>

				<el-form-item label="โทรศัพท์มือถือ" prop="mobile">
					<el-input v-model="addressForm.mobile" maxlength="11" placeholder="หมายเลขโทรศัพท์มือถือของผู้รับสินค้า"></el-input>
				</el-form-item>

				<el-form-item label="โทรศัพท์">
					<el-input v-model="addressForm.telephone" placeholder="เบอร์โทรศัพท์（เสริม）"></el-input>
				</el-form-item>

				<el-form-item class="area" label="บริเวณ" prop="area">
					<el-row :gutter="10">
						<el-col :span="7">
							<el-select prop="province" ref="province" v-model="addressForm.province_id" @change="getAddress(1)" placeholder="โปรดเลือกจังหวัด">
								<el-option label="โปรดเลือกจังหวัด" value="0"></el-option>
								<el-option v-for="item in pickerValueArray" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-col>
						<el-col :span="7">
							<el-select ref="city" prop="city" v-model="addressForm.city_id" @change="getAddress(2)" placeholder="โปรดเลือกเมือง">
								<el-option label="โปรดเลือกเมือง" value="0"></el-option>
								<el-option v-for="item in cityArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-col>
						<el-col :span="7">
							<el-select ref="district" prop="district" v-model="addressForm.district_id" placeholder="โปรดเลือกโซน/เขต">
								<el-option label="โปรดเลือกโซน/เขต" value="0"></el-option>
								<el-option v-for="item in districtArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-col>
					</el-row>
				</el-form-item>

				<el-form-item label="ที่อยู่โดยละเอียด" prop="address">
					<el-input v-model="addressForm.address" placeholder="ค้นหาตําแหน่งเซลล์、ถนน、ออฟฟิศ"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">ยกเลิก</el-button>
				<el-button type="primary" @click="addmemberAddress('form')">เพิ่มข้อมูล</el-button>
			</span>
		</el-dialog>

		<!--ใช้ยอดเงินคงเหลือ-->
		<div class="item-block" v-if="orderPaymentData.member_account.balance_total > 0 && balance_show == 1">
			<div class="block-text" style="    font-weight: 600;"  >ใช้ยอดเงินคงเหลือหรือไม่</div>
			<div class="pay-type-list">
				<div class="pay-type-item" :class="orderCreateData.is_balance ? '' : 'active'" @click="useBalance(0)">ไม่ใช้ยอดเงินคงเหลือ</div>
				<div class="pay-type-item" :class="orderCreateData.is_balance ? 'active' : ''" @click="useBalance(1)">ใช้ยอดเงินคงเหลือ</div>
				<div class="clear"></div>
			</div>
		</div>

		<!--ใช้เครดิตคะแนน-->
		<div class="item-block" v-if="orderPaymentData.shop_goods_list.max_usable_point > 0">
			<div class="block-text" style="    font-weight: 600;" >จะใช้คะแนนหรือไม่</div>

			<div class="pay-type-list">
				<div class="pay-type-item" :class="orderCreateData.is_point ? '' : 'active'" @click="usePoint(0)">ไม่ได้ใช้คะแนน</div>
				<div class="pay-type-item" :class="orderCreateData.is_point ? 'active' : ''" @click="usePoint(1)">ใช้คะแนน</div>
				<div class="clear"></div>
			</div>
		</div>

		<!-- วิธีการจัดส่ง -->
		<div class="item-block padd-bom-20" v-if="orderPaymentData.shop_goods_list.express_type.length > 1">
			<div class="block-text" style="    font-weight: 500;" >วิธีการจัดส่ง
				<span class="distribution" v-if="orderPaymentData.delivery_type=='store'">{{orderCreateData.delivery.store_name}}</span>
			</div>
			<div class="pay-type-item" v-for="(deliveryItem, deliveryIndex) in orderPaymentData.shop_goods_list.express_type"
			 :key="deliveryIndex" @click="selectDeliveryType(deliveryItem, orderPaymentData.site_id, orderPaymentData.shop_goods_list.express_type)"
			 :class="orderPaymentData.delivery_type == deliveryItem.name ? 'active' : ''" v-if="deliveryItem.name != 'local'">
				{{ deliveryItem.title }}
			</div>

		</div>
		
		<div class="item-block" v-if="orderPaymentData.shop_goods_list.invoice">
			<div class="block-text" style="    font-weight: 500;"  >ข้อมูลใบแจ้งหนี้</div>
			<div class="pay-type-list">
				<div class="pay-type-item" :class="orderCreateData.is_invoice == 0 ? 'active' : ''" @click="changeIsInvoice">ไม่ต้องใช้ใบแจ้งหนี้</div>
				<div class="pay-type-item" :class="orderCreateData.is_invoice == 1 ? 'active' : ''" @click="changeIsInvoice">ต้องมีใบแจ้งหนี้</div>
				<div class="clear"></div>
			</div>
			<div class="invoice-information" v-if="orderCreateData.is_invoice == 1">
				<div class='invoice-title'>
					<div class="invoice-type-box invoice-title-box" style="
    margin: 10px;" >
						<span class="invoice-name" style="    font-weight: 500;" >ชนิดเอกสาร：</span>
						<label class="invoice-to-type">
							<i class="invoice-i-input" :class="orderCreateData.invoice_type == 1 ? 'active' : '' " @click="clickType(1)"></i>
							<!-- <i v-if="orderCreateData.invoice_type == 1"></i>
							<input type="radio" name="invoice_type" value="1" @click="clickType(1)" :checked="orderCreateData.invoice_type == 1 ? 'checked' : '' "/> -->
							เอกสารจัดส่ง
						</label>
						<label class="invoice-to-type">
							<i class="invoice-i-input" :class="orderCreateData.invoice_type == 2 ? 'active' : '' "  @click="clickType(2)"></i>
							<!-- <i v-if="orderCreateData.invoice_type == 2"></i>
							<input type="radio" name="invoice_type" value="2" @click="clickType(2)" :checked="orderCreateData.invoice_type == 2 ? 'checked' : '' "/> -->
							เอกสารอิเล็กทรอนิกส์
						</label>
					</div>
					<div class="invoice-type-box invoice-title-box" style="
    margin: 10px;">
						<span class="invoice-name" style="    font-weight: 500;" >ประเภท：</span>
						<label class="invoice-to-type">
							<i class="invoice-i-input" :class="orderCreateData.invoice_title_type == 1 ? 'active' : '' "  @click="clickTitleType(1)"></i>
							<!-- <i v-if="orderCreateData.invoice_title_type == 1"></i>
							<input type="radio" name="invoice_rise_type" value="1" @click="clickTitleType(1)" :checked="orderCreateData.invoice_title_type == 1 ? 'checked' : '' "/> -->
							บุคคล
						</label>
						<label class="invoice-to-type">
							<i class="invoice-i-input" :class="orderCreateData.invoice_title_type == 2 ? 'active' : '' "  @click="clickTitleType(2)"></i>
							<!-- <i v-if="orderCreateData.invoice_title_type == 2"></i>
							<input type="radio" name="invoice_rise_type" value="2" @click="clickTitleType(2)" :checked="orderCreateData.invoice_title_type == 2 ? 'checked' : '' "/> -->
							นิติบุคคล
						</label>
					</div>
				</div>
				<div class="invoice-type-box" style="
    margin: 20px;" >
					<span class="invoice-name" style="    font-weight: 500;" >กรอก：</span>
					<div class="invoice-box-form">
						<input type="text" placeholder="กรุณากรอกชื่อส่วนหัว" v-model.trim="orderCreateData.invoice_title"/>
						<input type="text" placeholder="กรุณากรอกหมายเลขประจําตัวผู้เสียภาษีอากร" v-model.trim="orderCreateData.taxpayer_number" v-if="orderCreateData.invoice_title_type == 2"/>
						<input type="text" placeholder="กรุณากรอกที่อยู่ทางไปรษณีย์" v-model.trim="orderCreateData.invoice_full_address" v-if="orderCreateData.invoice_type == 1"/>
						<input type="text" placeholder="กรุณากรอกในกล่องจดหมาย" v-model.trim="orderCreateData.invoice_email" v-if="orderCreateData.invoice_type == 2"/>
					</div>
				</div>
				<div class="invoice-condition" style="
    margin: 20px;">
					<span class="invoice-name" style="    font-weight: 500;">ชื่อเอกสาร：</span>
					<div class="invoice-box-form">
						<span class="option-item" 
						:key="index"	
						v-for="(item, index) in orderPaymentData.shop_goods_list.invoice.invoice_content_array"
						@click="changeInvoiceContent(item)"
						:class="{ 'color-base-bg active': item == orderCreateData.invoice_content }"
						>
						{{item}}
						</span>
					</div>
				</div>
			</div>
		
		</div>
		
		<!--ข้อมูลผลิตภัณฑ์-->
		<div class="item-block">
			<div class="goods-list">
				<table>
					<tr>
						<td width="50%" style="    font-weight: 500; font-size:17px;" >สินค้า</td>
						<td width="12.5%" style="    font-weight: 500; font-size:17px;" >ราคา</td>
						<td width="12.5%" style="    font-weight: 500; font-size:17px;" >ปริมาณ</td>
						<td width="12.5%" style="    font-weight: 500; font-size:17px;" >มูลค่ารวม</td>
					</tr>
				</table>
			</div>
		</div>
		<div>
			<div class="item-block">
				<div class="goods-list">
					<table>
						<tr v-for="(goodsItem, goodsIndex) in orderPaymentData.shop_goods_list.goods_list" :key="goodsIndex">
							<td width="50%">
								<div class="goods-info">
									<div class="goods-info-left">
										<router-link :to="{ path: '/sku-' + goodsItem.sku_id }" target="_blank">
											<img class="goods-img" :src="$img(goodsItem.sku_image, { size: 'mid' })" @error="imageError(goodsIndex)" />
										</router-link>
									</div>
									<div class="goods-info-right">
										<router-link :to="{ path: '/sku-' + goodsItem.sku_id }" target="_blank">
											<div class="goods-name">{{ goodsItem.sku_name }}</div>
										</router-link>
										<!-- สเปค -->
										<div class="goods-spec" v-if="goodsItem.sku_spec_format">
											<span v-for="(x, i) in goodsItem.sku_spec_format" :key="i">{{ x.spec_value_name }} {{ i < goodsItem.sku_spec_format.length - 1 ? '; ' : '' }}</span>
										</div>
									</div>
								</div>
							</td>
							<td width="12.5%" class="goods-price">฿{{ goodsItem.price }}</td>
							<td width="12.5%" class="goods-num">{{ goodsItem.num }}</td>
							<td width="12.5%" class="goods-money">฿{{(goodsItem.price * goodsItem.num).toFixed(2)}}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>

		<!--คูปอง-->
		<div class="item-block" v-if="orderPaymentData.shop_goods_list.coupon_list.length">
			<div class="block-text">คูปอง</div>
			<div class="order-cell platform-coupon">
				<div v-if="orderPaymentData.coupon_money!=0">
					<span class="ns-text-color" @click="openPlatformCoupon">คูปองที่เลือกแล้ว，มีการทําข้อเสนอแล้ว</span>
					<span class="ns-text-color" @click="openPlatformCoupon">
						<span class="inline">฿</span>
						{{ orderPaymentData.coupon_money.toFixed(2) }}
					</span>
				</div>

				<div v-else>
					<div class="box ns-text-color" @click="openPlatformCoupon">ไม่ได้ใช้คูปอง</div>
				</div>
			</div>
		</div>

		<!-- ข้อความจากผู้ซื้อ -->
		<div class="item-block padd-bom-10">
			<div class="block-text">ข้อความจากผู้ซื้อ:</div>
			<el-input rows="3" type="textarea" placeholder="ขอแนะนําให้ประสานงานกับผู้ขายก่อนฝากข้อความ" v-model="orderCreateData.buyer_message" class="buyer-message"
			 @input="textarea" maxlength="140" show-word-limit resize="none"></el-input>
		</div>
		<!-- ทั้งหมด -->
		<div class="item-block">
			<div class="order-statistics">
				<table>
					<tr>
						<td align="right">จํานวนสินค้า：</td>
						<td align="left">+฿{{ orderPaymentData.goods_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.is_virtual == 0">
						<td align="right">ค่าขนส่ง：</td>
						<td align="left">+฿{{ orderPaymentData.delivery_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.invoice_money > 0">
						<td align="right">ภาษี：</td>
						<td align="left">+฿{{ orderPaymentData.invoice_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.invoice_delivery_money > 0">
						<td align="right">ไปรษณีย์ใบแจ้งหนี้：</td>
						<td align="left">฿{{ orderPaymentData.invoice_delivery_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.promotion_money > 0">
						<td align="right">สิทธิพิเศษ：</td>
						<td align="left">฿{{ orderPaymentData.promotion_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.point_money > 0">
						<td align="right">คะแนน：</td>
						<td align="left">-฿{{ orderPaymentData.point_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.coupon_money > 0">
						<td align="right">คูปอง：</td>
						<td align="left">-฿{{ orderPaymentData.coupon_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.balance_money > 0">
						<td align="right">ใช้ยอดเงินคงเหลือ：</td>
						<td align="left">฿{{ orderPaymentData.balance_money | moneyFormat }}</td>
					</tr>
				</table>
			</div>
			<div class="clear"></div>
		</div>

		<!--นิคม-->
		<div class="item-block">
			<div class="order-submit">
				<div class="order-money">
					สินค้าทั้งหมด {{ orderPaymentData.goods_num }} รายการ จํานวนเงินที่ต้องชําระ：
					<div class="ns-text-color">฿{{ orderPaymentData.pay_money | moneyFormat }}</div>
				</div>
				<el-button type="primary" class="el-button--primary" @click="orderCreate" style="    font-weight: 500; font-size:17px;     font-family: 'Prompt'; " >การชําระคําสั่งซื้อ</el-button>
			</div>
			<div class="clear"></div>
		</div>

		<!--เลือกกล่องป๊อปอัปคูปองแพลตฟอร์ม-->
		<el-dialog title="เลือกคูปอง" :visible.sync="dialogPlatcoupon" width="50%" @close="savePlatformCoupon()">
			<el-table ref="platformCouponTable" :data="orderPaymentData.shop_goods_list.coupon_list" highlight-current-row
			 @row-click="selectPlatformCoupon" class="cursor-pointer">
				<el-table-column label="" width="55">
					<template slot-scope="scope">
						<div class="disabled-selected-wrap">
							<el-radio v-model="platformCouponRadio" :label="scope.row"><i></i></el-radio>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="platformcoupon_name" label="ชื่อ" width="200">
					<template slot-scope="scope">
						<span>{{ scope.row.coupon_name }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="money" label="สิทธิพิเศษ"></el-table-column>
				<el-table-column label="ใช้">
					<template slot-scope="scope">
						<span class="ns-text-color-gray ns-font-size-sm" v-if="scope.row.at_least > 0">เต็ม{{ scope.row.at_least }}มีอยู่</span>
						<span class="ns-text-color-gray ns-font-size-sm" v-else>มีจํานวนเท่าใดก็ได้</span>
					</template>
				</el-table-column>
				<el-table-column label="เทอม">
					<template slot-scope="scope">
						<span>{{ $util.timeStampTurnTime(scope.row.end_time) }}</span>
					</template>
				</el-table-column>
			</el-table>
			<br />
			<div class="align-right">
				<el-button @click="dialogPlatcoupon = false">ยกเลิก</el-button>
				<el-button @click="dialogPlatcoupon = false" type="primary">ยืนยันการเลือกของคุณ</el-button>
			</div>
		</el-dialog>
		<!--วิธีการจัดส่ง  ร้าน ค้า -->
		<el-dialog title="เลือกร้านค้า" :visible.sync="dialogStore" width="50%">

			<el-table ref="singleTable" :data="storeList" highlight-current-row @row-click="selectStore" class="cursor-pointer">
				<el-table-column label="" width="55">
					<template slot-scope="scope">
						<el-radio v-model="storeRadio" :label="scope.row"><i></i></el-radio>
					</template>
				</el-table-column>
				<el-table-column prop="store_name" label="ชื่อ" width="160"></el-table-column>
				<el-table-column prop="store_address" label="ที่อยู่"></el-table-column>
				<el-table-column prop="open_date" label="เวลาทําการ"></el-table-column>
			</el-table>
		</el-dialog>

		<!-- วิธีการจัดส่ง ซื้อกลับบ้าน -->
		<!-- 		<el-dialog title="เวลาจัดส่ง" :visible.sync="deliveryTime" width="400px">
			<el-form status-icon ref="ruleForm" label-width="100px">
				<el-form-item label="เวลาจัดส่ง">
					<el-col :span="11">
						<el-time-picker format="HH:mm" value-format="HH:mm" placeholder="เลือกเวลา" :value="time" v-model="time" start="09:01"
						 end="21:01" @change="bindTimeChange"></el-time-picker>
					</el-col>
				</el-form-item>
				<el-form-item label="">
					<el-col :span="20">
						<div class="align-right">
							<el-button size="small" @click="deliveryTime = false">ปิดเครื่อง</el-button>
							<el-button type="primary" size="small" @click="setDeliveryTime(siteDelivery.site_id)">ยืนยันการเลือกของคุณ</el-button>
						</div>
					</el-col>
				</el-form-item>
			</el-form>
		</el-dialog> -->

		<!-- รหัสผ่านการชําระเงิน -->
		<el-dialog title="ใช้ยอดเงินคงเหลือ" :visible.sync="dialogpay" width="350px">
			<template v-if="orderPaymentData.member_account.is_pay_password == 0">
				<p>เพื่อความปลอดภัยของบัญชีของคุณ,โปรดตั้งรหัสผ่านการชําระเงินก่อน</p>
				<p>มีอยู่"ศูนย์สมาชิก","ความปลอดภัยของบัญชี","รหัสผ่านการชําระเงิน"การตั้งค่า</p>
				<span slot="footer" class="dialog-footer">
					<el-button size="small" @click="dialogpay = false">ยังไม่ได้ตั้งค่า</el-button>
					<el-button size="small" type="primary" @click="setPayPassword">ตั้งค่าเดี๋ยวนี้</el-button>
				</span>
			</template>
			<el-form v-else status-icon ref="ruleForm" label-width="100px">
				<el-form-item label="รหัสผ่านการชําระเงิน" class="pay-password-item">
					<!--เพิ่มสิ่งที่มองไม่เห็นinput,การป้อนอัตโนมัติของเบราว์เซอร์ปลอมแปลง-->
					<el-input type="password" class="pay-password hide-password" :maxlength="6"></el-input>
					<el-input type="password" class="pay-password" :maxlength="6" v-model="password" @input="input"></el-input>
				</el-form-item>
				<p class="ns-text-color forget-password" @click="setPayPassword">ลืมรหัสผ่าน</p>
			</el-form>
		</el-dialog>

		<!--เลือกกล่องป๊อปอัพคูปอง-->
		<!-- <el-dialog title="เลือกคูปอง" :visible.sync="dialogCoupon" width="50%">
			<el-table ref="couponTable" :data="siteCoupon.data" highlight-current-row @row-click="selectCoupon" class="cursor-pointer">
				<el-table-column label="" width="55">
					<template slot-scope="scope">
						<div class="disabled-selected-wrap">
							<el-radio v-model="couponRadio" :label="scope.row"><i></i></el-radio>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="coupon_name" label="ชื่อ" width="200"></el-table-column>
				<el-table-column label="สิทธิพิเศษ">
					<template slot-scope="scope">
						<span v-if="scope.row.type == 'reward'">{{ scope.row.money }}</span>
						<span v-if="scope.row.type == 'discount'">{{ scope.row.discount }} พับ</span>
					</template>
				</el-table-column>
				<el-table-column prop="use" label="ใช้"></el-table-column>
				<el-table-column prop="time" label="เทอม"></el-table-column>
			</el-table>
			<br />
			<div class="align-right">
				<el-button @click="dialogCoupon = false">ยกเลิก</el-button>
				<el-button @click="saveCoupon()" type="primary">ยืนยันการเลือกของคุณ</el-button>
			</div>
		</el-dialog> -->
	</div>
</template>

<script>
	import PicZoom from 'vue-piczoom';
	import detail from './payment.js';
	export default {
		name: 'payment',
		components: {
			PicZoom
		},
		mixins: [detail]
	};
</script>

<style lang="scss" scoped>
	@import './payment.scss';
</style>
